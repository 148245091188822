
<div class="booking-container">
  <div class="booking_background">
    <img class="img_class" src="assets/icons/backgroundbg.svg" />
    <div class="overlay">
      <div class="spacer">
        <div class="bold-white">Book Gold</div>
        <div class="regular-13" style="color: #fff" (click)="analytics.logEvent('viewall-bookingpage-btn')"    routerLink="/dashboard/view-bookings">
          View all bookings
          <span><img src="assets/icons/back-arrow.svg" /></span>
        </div>
      </div>
      <!-- <div class="button-container">
        <button
        [disabled]="!is_today_date || booking_validation.length==0"
          (click)="activeButton === 'weight'?
          toggleButton('amount'):
          '';
          analytics.logEvent('amount-bookingpage-btn')"

          [ngClass]="{ active: activeButton === 'amount' }"
          style="margin-right: -10px"
        >
          Amount
        </button>
        <button
        [disabled]="!is_today_date || booking_validation.length==0"
          (click)="activeButton === 'amount'?
           toggleButton('weight')
           :'';
           analytics.logEvent('weight-bookingpage-btn')"
          [ngClass]="{ active: activeButton === 'weight' }"
        >
          Weight
        </button>
      </div> -->
      <div>
        <div class="regular-13" style="color: white">
          {{
            activeButton == "amount"
              ? "Advance amount (In ₹)"
              : "Weight (In Gms)"
          }}
        </div>
        <div>
         
              <input
              (keypress)="preventDecimal($event)"
              (paste)="preventDecimal($event)"
              pattern="\d*"
            [disabled]="!is_today_date || booking_validation.length==0"
              class="regular-13 form-control advance_amount_field rid"
              (input)="bookingValidation($event)"
              type="number"
              [(ngModel)]="amount_or_weight"
              [placeholder]="
                activeButton == 'amount'
                  ? 'Enter the booking amount'
                  : 'Enter weight (Min 5 gms)'
                  
              "
              type="number"
              
            />
              <!-- Display validation errors -->

          
        </div>
      </div>
      <div class="regular-13 mt-1 note">
        Note:
        <ul>
          <li> You can make multiple bookings but each booking should not exceed 1 Lakh.</li>
          <li>        The minimum booking weight should be 5 grams.</li>
        </ul>
      </div>
    </div>
  </div>

</div>
<div id="grams_block" class="booking_details p-2">
  <div >
  <div class="booking">
    <div class="regular-13 reg-13-grey">You will book</div>
    <div class="purple_text">{{payable_or_gram ? payable_or_gram :0}} grams</div>
    <div class="regular-13 extra_Reg">
      of 22k-916 Gold for the amount you entered
    </div>
    <hr style="border: 1px solid #aff2f9; width: 100%" />
    <div>
      <ul>
        <li class="regular-13 reg-13-grey">{{advance_percentage}}% Advance to be paid</li>
        <li class="regular-13 reg-13-grey">
          You can buy Gold jewellery anytime upto {{month}} months
        </li>
        <li class="regular-13 reg-13-grey">
          Once an advance booking is made, it is non-refundable and cannot be cancelled.
        </li>
      </ul>
    </div>
  </div>
  <div class="buy_now_block">
    <button
    [disabled]="!is_today_date || booking_validation.length==0"
      class="buy_now regular-16"
      (click)="error()?
       createBooking()
        :'';
        analytics.logEvent('booknow-bookingpage-btn')"
    >
      Book Now
    </button>
  </div>
</div>
</div>
<div  id="amount_block" class="booking_details p-2">
  <div class="booking">
    <div class="regular-13 reg-13-grey">Amount payable</div>
    <div class="purple_text">₹ {{payable_or_gram ? payable_or_gram :0}}</div>
    <div class="regular-13 extra_Reg">
      to book 22k-916 Gold weight you entered
    </div>
    <hr style="border: 1px solid #aff2f9; width: 100%" />
    <div>
      <ul>
        <li class="regular-13 reg-13-grey">{{advance_percentage}}% Advance to be paid</li>
        <li class="regular-13 reg-13-grey">
          You can buy Gold jewellery anytime upto {{month}} months
        </li>
        <li class="regular-13 reg-13-grey">
          Once an advance booking is made, it is non-refundable and cannot be cancelled.
        </li>
      </ul>
    </div>
  </div>
  <div class="buy_now_block">
    <button
    [disabled]="!is_today_date || booking_validation.length==0"
      class="buy_now regular-16"
      (click)="error()? createBooking():''"
    >
      Book Now
    </button>
  </div>
</div>

<ng-template #continue let-modal>
  <div class="myModal">
    <div class="modal-content p-2">

      <div class="modal-body">
        <div class="text-center">

          <h1 class="fbold mt-2">Today's Rates Not Updated!</h1>

          <p
            class="warning_text"

          >
          Rates unavailable. You will be notified once rates are updated          </p>

          <button
            (click)="modal.dismiss('Cross click')"
            type="button"
            class="btn buy_now mt-3"
            routerLink="/dashboard/home"

          >
          Go to Home
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>