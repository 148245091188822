import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Rest} from "src/app/model/restModel";


export interface PlanModel{
    name:string;
    id:string;
    description:string;
    terms:string;
    duration:number;
    minimum_cost:string;
    maximum_cost:string;
  }


 export class CreateSavingsResponseModel{
    gateway_id:string="";
    gateway_type!:number;
    order_id:string="";
    gateway_order_id:string="";


  }

export class BillingDetailModel{
  //used in home.services , savings.services, emi-order-list.services
    first_name:string="";
    address:string="";
    pincode:string="";
    state:string="";
    phone:string="";
    email:string="";

  trimData(){
    this.first_name=this.first_name.trim();
    this.address=this.address.trim();
    this.pincode=String(this.pincode).trim();
    this.state=this.state.trim();
  }

  validateData(){
    // Returns string if error, else returns null
    this.trimData();
    if(this.first_name=="") return "Please Input a valid Name";
    if(this.first_name.length<4) return "Name is too short";
    if(this.address.length<15) return "Address is too short";
    if(this.address=="") return "Please Input a valid Address";
    if(this.pincode=="") return "Please Input pincode";
    if(this.pincode.length!=6) return "Pincode should be exactly 6 digits long";
    if(this.state=="") return "Please Input a valid state";
    return null;
  }
}

@Injectable({
    providedIn: 'root'
  })
export class SavingsService {



  constructor(public http:HttpClient) {}

  getPlans(){
    return this.http.get<Rest<PlanModel>>(`${environment.ip}/app/plans/`);
  }


  getPlan(plan_id:string){
    return this.http.get<PlanModel>(`${environment.ip}/app/plans/${plan_id}/`);
  }

  getBillingDetail(){
    //SAME CODE REPLICATED IN HOME PAGE
    return this.http.get<BillingDetailModel>(`${environment.ip}/app/billingInfo/getInfo/`)
  }

  saveBillingDetail(billingData:BillingDetailModel){
    return this.http.post<BillingDetailModel>(`${environment.ip}/app/billingInfo/`
    ,{first_name:billingData.first_name,
    pincode:billingData.pincode,
    state:billingData.state,
    address:billingData.address});
  }

  createSavingsOrder(plan:string, cost:string){
    return this.http.post<CreateSavingsResponseModel>(`${environment.ip}/app/subscription/`
        ,{plan:plan,
          gateway_type:0,
          cashfree_activated:true,
         cost:cost});
  }



}
