import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
// import { HomeComponent } from './home/home.component';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
// import {DialogComponent} from './template/dialog/dialog.component';
// import {DialogComponent} from './savings/savings-cust-data/savings-cust-data.component';

// import { TemplateComponent} from './template/template.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';
// import { SavingsComponent } from './savings/savings.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
// Add these two
import { LottieModule } from 'ngx-lottie';
import {LoginComponent} from './login/login.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { HeaderInterceptor } from './services/header.interceptor';

// import { MainNavComponent } from './main-nav/main-nav.component';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule} from '@angular/fire/compat';
import { AngularFireAnalyticsModule,ScreenTrackingService  } from '@angular/fire/compat/analytics';
import { environment } from 'src/environments/environment';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NoLoginGuard } from './services/no-login-guard.service';
import { StatusPageComponent } from './status-page/status-page.component';
import { PrivacyComponent } from './privacy/privacy.component';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import { AboutUsComponent } from './about-us/about-us.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { DeveloperPageComponent } from './developer-page/developer-page.component';
import { BookingsComponent } from './bookings/bookings.component';
import { LogoutComponent } from './logout/logout.component';
import { StoreComponent } from './store/store.component';


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const app = initializeApp(environment.firebase);
const analytics = getAnalytics(app);


export function playerFactory(): any {
  return import('lottie-web');
}



@NgModule({
  declarations: [
    AppComponent,
    // MainNavComponent,
    LoginComponent,
    PrivacyComponent,
    StatusPageComponent,
    AboutUsComponent,
    RefundPolicyComponent,
    PaymentPageComponent,
    BookingsComponent,
    LogoutComponent,
    StoreComponent,


    // BookingDetailsComponent,
    // ViewBookingsComponent,
    // HomeComponent,
    // TemplateComponent
    // SavingsComponent,
    // SavingsCustDataComponent,
    // DialogComponent,
    // MysavingsComponent,
    // EmiOrderListComponent,
    // CategoryComponent,
    // MyAccountComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // MatToolbarModule,
    // MatRippleModule,
    MatIconModule,
    MatButtonModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireModule,
    AngularFireAnalyticsModule,
    // MatSidenavModule,
    // NgbModule,
    // MatDialogModule,
    // MatDividerModule,
    // MatSelectModule,
    HttpClientModule,
    FormsModule,
    MatSnackBarModule,
    // LottieModule.forRoot({ player: playerFactory }),
    NgOtpInputModule,
    MatFormFieldModule,
    // NgxGoogleAnalyticsModule.forRoot(environment.google_analytics_measurement)
    LottieModule.forRoot({ player: playerFactory }),
    ReactiveFormsModule


  ],

  providers: [NoLoginGuard,ScreenTrackingService],
  bootstrap: [AppComponent],
})
export class AppModule { }
